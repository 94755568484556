import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { storeUserDetailsAction, saveAccessTokenAction, saveAzureTokenAction } from "../src/Redux/Actions/actions";
import { MasterRoute, GLRoute, BMRoute, SERoute, OtherRoute } from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import IdleTimer from "react-idle-timer";
import {
  authenticateUserWithMyID,
} from "./Redux/APIs/api_Login";
import { useMsal } from '@azure/msal-react';


export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [isLoader, setIsLoader] = useState(false);
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isAuthorized, setIsAuthorized] = useState(true);

  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }
  function authenticateUserWithGID(gid) {
    authenticateUserWithMyID(dispatch, activeAccount.idToken, gid)
      .then((response) => {
        if (response) {
          console.log("app", response)
          setIsLoader(false);
          setIsAuthorized(false);
        } else {
          setIsLoader(false);
          setIsAuthorized(false);
        }
      })
      .catch((error) => {
        setIsLoader(false);
      });
  }

  useEffect(() => {
    console.log(activeAccount);
    console.log(userDetails)
    if (activeAccount && isAuthorized && !userDetails.userId) {
      setIsLoader(true);
      dispatch(saveAccessTokenAction(activeAccount.idToken));
      dispatch(saveAzureTokenAction(activeAccount.idToken));
      authenticateUserWithGID();
    }
    else {

      setIsLoader(false);

    }


  }, [instance, activeAccount, !userDetails.userId]);
  return (
    <>
      <BrowserRouter>
        <div className="ssiat-master-block">
          {auth.isLoading || isLoader ? (
            <Loader />
          ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
            <>
              <Header>Header Component</Header>
              <main className="main-component">{userDetails.roleId === 1 ? MasterRoute :
                userDetails.roleId === 5 ? GLRoute : userDetails.roleId === 6 ? SERoute : userDetails.roleId === 10 ? BMRoute : OtherRoute}</main>
            </>
          ) : (userDetails && userDetails?.userData?.respMsg?.length > 0) ? OtherRoute : (
            LoginRoute
          )}
        </div>
        <div>
          <IdleTimer
            timeout={1000 * 60 * 15}
            onIdle={sessionExpire}
            debounce={250}
          />
        </div>
        <>
        </>
      </BrowserRouter>
    </>
  );
}
